<template>
  <div v-if="dataInfo" id="orderInfo">
    <div class="header">
      <!-- 待付款 -->
      <div v-if="statusText == language.l_25" class="header-left">
        <!-- 等待买家付款 -->
        <span>{{language.l_200}}</span>
        <!-- 逾期未支付，订单自动取消 -->
        <span>{{language.l_201}}</span>
      </div>
      <!-- 交易完成 -->
      <div v-else-if="statusText == language.l_46" class="header-left">
        <!-- 交易完成 -->
        <span>{{language.l_46}}</span>
        <!-- 感谢您的购买 -->
        <span>{{language.l_202}}</span>
      </div>
      <div v-else class="header-left">
        <!-- 待发货    等待发货 -->
        <span v-if="statusText == language.l_44">{{language.l_203}}</span>
        <!-- 待出库    等待出库 -->
        <span v-else-if="statusText == language.l_39">{{language.l_204}}</span>
        <!-- 已发货    已发货 -->
        <span v-else-if="statusText == language.l_42">{{language.l_42}}</span>
        <!-- 自提    自提 -->
        <span v-else-if="statusText == language.l_40">{{language.l_40}}</span>
        <!-- 部分发货    部分发货 -->
        <span v-else-if="statusText == language.l_45">{{language.l_45}}</span>
        <!-- 已签收    已签收 -->
        <span v-else-if="statusText == language.l_43">{{language.l_43}}</span>
        <!-- 退款处理中    退款处理中 -->
        <span v-else-if="statusText == language.l_50">{{language.l_50}}</span>
        <!-- 售后中    售后申请中 -->
        <span v-else-if="statusText == language.l_48">{{language.l_205}}</span>
        <!-- 已退款    退款成功 -->
        <span v-else-if="statusText == language.l_51">{{language.l_206}}</span>
        <!-- 交易取消    交易已取消 -->
        <span v-else-if="statusText == language.l_34">{{language.l_207}}</span>
        <!-- 售后完成    售后完成 -->
        <span v-else-if="statusText == language.l_52">{{language.l_52}}</span>
      </div>
      <!-- 待付款 -->
      <img v-if="statusText == language.l_25" src="@img/order/orderInfo/card.png" alt="">
      <!-- 交易完成 -->
      <img v-else-if="statusText == language.l_46" src="@img/order/orderInfo/success.png" alt="">
      <!-- 已退款    交易取消 -->
      <img v-else-if="statusText == language.l_51 || statusText == language.l_34" src="@img/order/orderInfo/close.png" alt="">
    </div>
    <!-- 地址 -->
    <template v-if="dataInfo.attribute === 2">
      <div></div>
    </template>
    <template v-else>
      <div v-if="dataInfo.province" class="address">
        <div class="address-left">
          <p class="iconfont icon_address"></p>
          <div class="address-info">
            <div class="address-name">
              <p class="van-ellipsis">{{dataInfo.consignee}}</p>
              <p>{{dataInfo.mobile}}</p>
            </div>
            <p>{{dataInfo.receiver_identity_card}}</p>
            <p>{{address}}</p>
          </div>
        </div>
      </div>
      <div v-else @click="toAddress(dataInfo.id)" class="address-noGet">
        <div class="address-noGet-wrap">
          <div class="address-noGet-left">
            <svg class="icon" aria-hidden="true" style="height: 50px;">
              <use xlink:href="#icon_add"></use>
            </svg>
            <!-- 手动添加收货地址 -->
            <p class="address-noGet-text">{{language.l_190}}</p>
          </div>
          <van-icon name="arrow" color="#999"/>
        </div>
      </div>
    </template>
    <!-- 商品详情 -->
    <div class="container">
      <div v-for="(item, index) in dataInfo.products" :key="index" @click="toGoodsInfo(item.goods_id)" class="content">
        <van-card>
          <template v-slot:thumb>
            <van-image fit="contain" :src="item.goods_pic"/>
          </template>
          <template v-slot:title>
            <div class="content-wrap">
              <div class="content-left">
                <p class="content-title">{{item.goods_title}}</p>
                <p class="content-desc">{{item.product_name}}</p>
              </div>
              <div class="content-right">
                <p class="content-nowrap">x {{item.sale_price}}</p>
                <p class="goodsNum">x {{item.number}}</p>
              </div>
            </div>
          </template>
        </van-card>
      </div>
      <div class="container-footer">
        <div class="container-footer-item">
          <!-- 运费 -->
          <span>{{language.l_196}}</span><span>${{dataInfo.shipping_fee}}</span>
        </div>
        <div class="container-footer-item">
          <!-- 应付金额 -->
          <span>{{language.l_208}}</span><span>${{dataInfo.expect_pay_amount}}</span>
        </div>
        <div class="container-pri">
          <!-- 实付金额 -->
          <span>{{language.l_209}}</span><span>${{dataInfo.pay_amount}}</span>
        </div>
      </div>
    </div>
    <div class="orderInfo">
      <van-cell-group>
        <!-- 订单编号： -->
        <van-cell :title="`${language.l_210}${dataInfo.order_sn}`">
          <template #right-icon>
            <!-- 复制 -->
            <p class="orderInfo-copy van-hairline--surround" v-clipboard:copy="dataInfo.order_sn" v-clipboard:success="copySuccess" v-clipboard:error="copyError">{{language.l_211}}</p>
          </template>
        </van-cell>
        <!-- 支付方式： -->
        <van-cell :title="`${language.l_212}${dataInfo.pay_name}`"/>
        <!-- 下单时间： -->
        <van-cell :title="`${language.l_213}${dataInfo.create_time}`"/>
      </van-cell-group>
    </div>
    <div class="fixed van-hairline--top">
      <div class="fixed-wrap">
        <!-- 交易取消    删除订单 -->
        <p v-if="statusText == language.l_34" @click="delOrders(dataInfo.id)" class="isBlack van-hairline--surround">{{language.l_35}}</p>
        <!-- 待付款    取消订单 -->
        <p v-if="statusText == language.l_25" @click="openClosePop('cancel')" class="isBlack van-hairline--surround">{{language.l_36}}</p>
        <!-- 待付款    重新支付 -->
        <p v-if="statusText == language.l_25 && dataInfo.province" @click="openPayPop" class="isBlue van-hairline--surround">{{language.l_37}}</p>
        <!-- 待出库    自提    申请退款 -->
        <p v-if="statusText == language.l_39 || statusText == language.l_40" @click="openClosePop('refund')" class="isBlack van-hairline--surround">{{language.l_41}}</p>
        <!-- 待发货    已发货    部分发货    已签收    交易完成    自提    申请售后 -->
        <p v-if="statusText == language.l_44 || statusText == language.l_42 || statusText == language.l_45 || statusText == language.l_43 || statusText == language.l_46 || statusText == language.l_40" @click="toGetService(dataInfo.id, dataInfo.order_sn)" class="isBlack van-hairline--surround">{{language.l_47}}</p>
        <!-- 售后中    售后进度 -->
        <p v-if="statusText == language.l_48" @click="toServiceInfo(dataInfo.after_sale_id)" class="isBlue van-hairline--surround">{{language.l_49}}</p>
        <!-- 退款处理中    已退款    售后完成    交易完成    再去逛逛 -->
        <p v-if="statusText == language.l_50 || statusText == language.l_51 || statusText == language.l_52 || statusText == language.l_46" @click="toHome" class="isBlack van-hairline--surround">{{language.l_53}}</p>
        <!-- 已发货    自提    确认收货 -->
        <p v-if="statusText == language.l_42 || statusText == language.l_40" @click="confirmRec(dataInfo.id)" class="isBlue van-hairline--surround">{{language.l_54}}</p>
      </div>
    </div>
    <!-- 取消订单弹窗 -->
    <van-popup v-model="cancelFlag" @close="cancelClose('cancel')" get-container="#app" position="bottom" safe-area-inset-bottom class="cancel-reason">
      <div class="model-wrap">
        <!-- 取消订单 -->
        <p class="model-header">{{language.l_36}}</p>
        <div class="model-wram">
          <!-- 温馨提示 -->
          <p class="model-wram-title">{{language.l_214}}</p>
          <ul class="model-wram-list">
            <!-- 订单一经取消，无法恢复 -->
            <li>{{language.l_215}}</li>
            <!-- 订单所用红包，优惠券支持退回 -->
            <li>{{language.l_216}}</li>
            <!-- 若您支付未成功，请及时联系客服 -->
            <li>{{language.l_217}}</li>
          </ul>
        </div>
        <!-- 请选择取消订单的原因： -->
        <p class="model-p">{{language.l_218}}</p>
        <div class="model-radios">
          <van-radio-group v-model="cancelRadio" :border="false" label-position="left">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in cancelReason" :key="index" @click="cancelRadio = item.value" :title="item.name" clickable>
                <van-radio :name="item.value"/>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <!-- 确定 -->
        <div class="model-btn" @click="confirmClose('cancel',dataInfo.id)">{{language.l_87}}</div>
      </div>
    </van-popup>
    <!-- 退款弹窗 -->
    <van-popup v-model="refundFlag" position="bottom" @close="cancelClose('refund')" class="cancel-reason" get-container="#app" safe-area-inset-bottom>
      <div class="model-wrap">
        <!-- 申请退款 -->
        <p class="model-header">{{language.l_41}}</p>
        <!-- 请选择退款的原因： -->
        <p class="model-p">{{language.l_219}}</p>
        <div class="model-radios">
          <van-radio-group v-model="refundRadio" :border="false" label-position="left">
            <van-cell-group :border="false">
              <van-cell v-for="(item, index) in refundReason" :key="index" @click="refundRadio = item.value" :title="item.name" clickable>
                <van-radio :name="item.value"/>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <!-- 确定 -->
        <div @click="confirmClose('refund', dataInfo.id)" class="model-btn">{{language.l_87}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {orderDetails, cancelOrder, cancellation, pay, getRefundReason, applyRefund, delOrder, receivedOrder} from '@api';
export default {
  data() {
    return {
      dataInfo: null,
      cancelFlag: false, // 取消订单弹窗
      cancelRadio: null, // 取消订单原因
      cancelReason: null, // 取消订单原因数据
      refundFlag: false, // 退款申请弹窗
      refundRadio: null, // 退款申请原因
      refundReason: null, // 退款申请原因数据
      isUpdate: false, // 是否更改了订单状态 - 返回后是否刷新订单列表页
      language: this.$store.getters.language
    }
  },
  created() {
    const {id} = this.$route.params;
    orderDetails(id).then(res => this._handleData(res.data))
  },
  methods: {
    _handleData(data) {
      const order_adr = this.$store.state.selectOrderAdr;
      if(order_adr && order_adr.hasOwnProperty(`${data.id}`)) {
        const {receiver_name, receiver_tel, receiver_identity_card, province, city, district, address} = order_adr[data.id]
        data.consignee = receiver_name
        data.mobile = receiver_tel
        data.receiver_identity_card = receiver_identity_card
        data.province = province
        data.city = city
        data.district = district
        data.address = address
      }
      this.dataInfo = data
    },
    copySuccess(e) {this.$toast.success(this.language.l_220)},  // 复制成功
    copyError(e) {this.$toast.fail(this.language.l_221)},  // 复制失败
    toAddress(id) {this.$router.push({name: 'MineAddress', params: {from: `order:${id}:${this.statusText}`}})},  // 设置收货地址
    openClosePop(type) { // 打开弹窗
     const {cancelReason, refundReason} = this.$store.state;
     if(type === 'cancel') {
        this.cancelFlag = true;
        if(!cancelReason) {
          cancellation().then(res => {
            this.cancelReason = res.data;
            this.$store.commit('setCancelReason', res.data);
          })
        } else {
          this.cancelReason = cancelReason
        }
      }else if (type === 'refund'){
        this.refundFlag = true;
        if(!refundReason) {
          getRefundReason().then(res => {
            this.refundReason = res.data;
            this.$store.commit('setRefundReason', res.data);
          })
        } else {
          this.refundReason = refundReason
        }
      }
    },
    cancelClose(type) {  // 关闭弹窗
      if(type === 'cancel') {
        if(this.cancelRadio) {this.cancelRadio = null}
      }else if(type === 'refund') {
        if(this.refundRadio) {this.refundRadio = null}
      }
    },
    confirmClose(type,id) { // 取消订单/退款
      const {cancelRadio, refundRadio: reason_id, dataInfo} = this;
      if(type === 'cancel') {
        if(!cancelRadio) return this.$toast(this.language.l_222);  //请选择一个原因
        cancelOrder({id, cancel_reason: cancelRadio}).then(() => {
          this.$toast.success(this.language.l_162);  //取消成功
          this.cancelFlag = false;
          this.cancelRadio = null;
          this.dataInfo.order_status = 3;
          this.dataInfo.shipping_status = 4;
          this.isUpdate = true;
        })
      }else if(type === 'refund') {
        if(!reason_id) return this.$toast(this.language.l_222);  //请选择一个原因
        applyRefund({id, reason_id, refund_amount: dataInfo.pay_amount}).then(() => {
          this.$toast.success(this.language.l_226);  //申请成功
          this.refundFlag = false;
          this.refundRadio = null;
          this.dataInfo.order_status = 5;
          this.isUpdate = true;
          
        })
      }
    },
    openPayPop() { // 重新支付
      const {dataInfo} = this
      const {id: order_id, address_id, pay_type,remark} = dataInfo

      let params = {
        order_id,
        address_id,
        pay_type,
        remark
      }
      
      this.$dialog.confirm({
        title: this.language.l_35,
        message: this.language.l_224,
      }).then(() => {
        pay(params).then(res => {
            this.isUpdate = true;
            // this.$store.commit('clearOrderAdr', order_id); // 清除选择的地址
            const status = {order_status: 0, pay_status: 1, shipping_status: 0}; // 待发货状态
            this.dataInfo = {...this.dataInfo, ...status}; // 更新当前付款订单状态
            this.$toast.success(this.language.l_199);  //支付成功
          })
        }).catch(() => {
          this.$toast.fail(this.language.l_225)  //支付取消
        });
    },
    delOrders(id) { // 删除订单
      this.$dialog.confirm({
        message: this.language.l_55,  //确定要删除吗，删除之后找不回哟~
        cancelButtonText: this.language.l_22,  //我再想想
        confirmButtonText: this.language.l_14  //删除
      }).then(() => {
        delOrder(id).then(res => {
          this.isUpdate = true;
          this.$toast.success(this.language.l_56);  //删除成功
          this.$store.commit('setDelayJump', () => this.$router.go(-1));
        })
      })
      .catch(err => this.$toast.fail(this.language.l_313))  //取消删除
    },
    confirmRec(id) { // 确认收货
      this.$dialog.confirm({message: this.language.l_57}).then(() => {  //是否确认收货？
        receivedOrder({id})
          .then(res => {
            this.isUpdate = true;
            this.dataInfo.shipping_status = 2;
            this.$toast.success(this.language.l_58);  //收货成功
          })
      })
      .catch(() => {
        this.$toast.fail(this.language.l_59)  //取消收货
      })
    },
    toGetService(id, order_sn) { // 跳转申请售后
      this.$router.push({
        name: 'ServiceApply',
        query: {id, order_sn}
      })
    },
    toServiceInfo(id) { // 跳转售后详情
      this.$router.push({name: 'ServiceInfo', params: {id}})
    },
    toHome() { // 跳转首页
      this.$router.push({name: 'Home'})
    },
    toGoodsInfo(id) {  //跳转商品详情
      this.$router.push({name: 'GoodsInfo', params: {id}})
    }
  },
  computed: {
    statusText() { // 订单状态
      return this.$getStatus(this.dataInfo);
    },
    address() {  //详细地址
      const {province, city, district, address} = this.dataInfo
      return `${province} ${city} ${district} ${address}`
    }
  },
  beforeRouteLeave (to, from, next) {
    if(to.name == 'OrderList') {to.meta.isUseCache = !this.isUpdate}
    next();
  }
}
</script>

<style lang="less" scoped>
#orderInfo {
  .safe_padding_bottom(50px);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-image: @grad_left;
    font-size: 14px;
    .header-left {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin-left: 10px;
      color: #fff;
      span {
        &:nth-of-type(1) {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
    img {
      width: 39px;
      height: 39px;
      margin-right: 20px;
    }
  }
  .address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 10px;
    .address-left {
      display: flex;
      flex: 1;
      align-items: center;
      .icon_address {
        font-size: 25px;
      }
      .address-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        // width: 240px;
        .address-name {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          p {
            &:first-child {
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              max-width: 150px;
              margin-right: 15px;
            }
          }
        }
        & > p {
          &:nth-of-type(2) {
            display: inline-block;
            margin-top: 10px;
            line-height: 1.4;
            word-break: break-all;
          }
        }
      }
    }
    .address-nav {
      width: 55px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      margin-right: 10px;
      color: @c_deep;
      &::after {
        border-radius: 10px;
        border-color: @c_deep;
      }
    }
  }
  .address-noGet {
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    background-color: #fff;
    .address-noGet-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 0;
      .address-noGet-left {
        display: flex;
        align-items: center;
        #icon_add {
          font-size: 18px;
        }
        .address-noGet-text {
          margin-left: 5px;
        }
      }
    }
  }
  .container {
    box-sizing: border-box;
    padding: 10px;
    background-color: #fff;
    margin-top: 10px;
    .wran {
      position: absolute;
      top: 2px;
      left: -7px;
      color: red;
    }
    .container-header {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      box-sizing: border-box;
      padding: 10px 0;
    }
    .container-card {
      position: relative;
      display: flex;
      justify-content: space-between;
      /* align-items: center; */
      height: 39px;
      line-height: 39px;
      font-size: 14px;
      .container-card-wrap {
        display: flex;
        align-items: center;
        .container-card-num {
          text-align: center;
          margin-left: 30px;
          width: 195px;
          .van-cell {
            padding: 0;
          }
        }
      }
      .vant-icon-question-o {
        width: 30px;
        text-align: right;
      }
    }
    .content {
      position: relative;
      margin-bottom: 10px;
      box-sizing: border-box;
      .van-card {
        box-sizing: border-box;
        padding: 10px;
        border-radius: 10px;
        .van-card__thumb {
          box-shadow: 1px 0px 2px rgba(0, 0, 0, .1);
          background-color: #fff;
          border-radius: 8px;
          .van-image {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .van-card__content {
          .content-wrap {
            display: flex;
            justify-content: space-between;
            .content-left {
              margin-right: 5px;
              line-height: 1.2;
              .content-desc {
                color: #999999;
                margin-top: 5px;
              }
            }
            .content-right {
              text-align: right;
              line-height: 1.2;
              .content-nowrap {
                white-space: nowrap;
              }
              .oldPrice {
                text-decoration: line-through
              }
              .bigSize {
                font-size: 12px;
              }
              .goodsNum,
              .goodsInfo {
                margin-top: 10px;
                color: #999999;
              }
            }
          }
        }
      }
    }
    .container-footer {
      font-size: 12px;
      color: #666666;
      .container-footer-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        & > span {
          &:nth-of-type(2) {
            color: #333;
          }
        }
        .container-footer-left {
          color: #999;
          p {
            &:nth-of-type(1) {
              // margin-right: 20px;
              color: #333333;
            }
          }
        }
        .container-footer-right {
          color: #333;
        }
      }
      .container-pri {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding-bottom: 10px;
        span {
          color: #333;
          &:nth-of-type(2) {
            color: red;
          }
        }
      }
    }
  }
  .service {
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    color: #666666;
    font-size: 14px;
    background-color: #fff;
    margin-bottom: 10px;
    .ser_item {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      .ser_l {
        margin-right: 20px;
      }
      .ser_r {
        display: flex;
        align-items: center;
        .ser_tag {
          height: 20px;
          line-height: 20px;
          padding: 0 9px;
          color: red;
          font-size: 12px;
          border-radius: 20px;
          &::after {
            border-color: red;
            border-radius: 20px;
          }
          & + .ser_tag {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .orderInfo {
    box-sizing: border-box;
    margin-top: 10px;
    color: #666666;
    font-size: 12px;
    background-color: #fff;
    margin-bottom: 10px;
    .orderInfo-copy {
      width: 50px;
      height: 20px;
      line-height: 21px;
      text-align: center;
      border-radius: 20px;
      &.van-hairline--surround {
        &::after {
          border-radius: 20px;
          border-color: #E5E5E5;
        }
      }
    }
  }
  .logistics {
    margin-bottom: 10px;
    color: #333;
    background-color: #fff;
    font-size: 12px;
    .logistics-wrap {
      box-sizing: border-box;
      padding: 10px;
      .logistics-head {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 6px;
          height: 100%;
          background-color: @c_deep;
          border-radius: 10px;
        }
        p {
          display: inline-block;
          width: 94%;
          font-weight: bold;
          margin-left: 14px;
        }
      }
      .logistics-body {
        position: relative;
        margin: 10px 0 15px 0;
        .logistics-p {
          margin-top: 5px;
          span {
            color: #999999;
          }
          .logistics-copy {
            display: inline-block;
            width: 50px;
            height: 20px;
            line-height: 21px;
            margin-left: 30px;
            text-align: center;
            border-radius: 20px;
            &.van-hairline--surround {
              &::after {
                border-radius: 20px;
                border-color: #E5E5E5;
              }
            }
          }
        }
        .iconfont {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 35px;
          height: 100%;
          text-align: center;
          line-height: 200%;
          transition: all ease 1s;
          -webkit-transition: all ease 1s;
          &.icon-trans {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
          }
        }
        .arrow-down {
          position: absolute;
          right: 0;
          top: 0;
          width: 35px;
          height: 100%;
          line-height: 200%;
          transition: all ease 1s;
          -webkit-transition: all ease 1s;
        }
      }
      .logistics-info {
        max-height: 0px;
        box-sizing: border-box;
        transition: all ease .5s;
        -webkit-transition: all ease .5s;
        &.logistics-showInfo {
          max-height: 1500px;
          overflow: hidden;
        }
        &.logistics-hideInfo {
          max-height: 0px;
          overflow: hidden;
        }
        .logistics-foot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .logistics-adr {
            width: 20%;
            text-align: center;
          }
          .logistics-progress-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            .logistics-progress,
            .logistics-progress-active {
              display: inline-block;
              width: 30px;
              height: 4px;
              margin-right: 4px;
              transform: skew(30deg);
              -webkit-transform: skew(30deg);
            }
            .logistics-progress {
              background-color: #999999;
            }

            .logistics-progress-active {
              background-color: @c_deep;
            }
          }
        }
        .logistics-steps {
          margin-top: 4px;
          .van-step--vertical {
            padding: 10px 10px 0 0;
            &::after {
              display: none;
            }
          }
          .van-step {
            .step-title {
              font-size: 14px;
            }
            .step-content {
              font-size: 12px;
            }
            .step-time {
              font-size: 12px;
              margin-top: 5px;
            }
          }
          /deep/ .van-step__title--active {
            color: @c_deep;
          }
          /deep/ .van-step__icon--active {
            color: @c_deep;
            font-size: 14px;
          }
          /deep/ .van-step__circle {
            width: 8px;
            height: 8px;
          }
        }
        .logistics-info-wrap {
          margin: 10px 0;
          .logistics-info-btn {
            width: 50px;
            padding: 1px 0;
            margin: 0 auto;
            text-align: center;
            font-size: 12px;
            color: #999;
            border-radius: 20px;
            &::after {
              border-radius: 20px;
              border-color: #999;
            }
          }
        }
      }
      
    }
  }
  .fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    .safe_padding_bottom();
    .fixed-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      height: 50px;
      padding: 10px;
      font-size: 12px;
      p {
        width: 75px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #666666;
        margin-left: 10px;
        &.isBlue {
          color: #fff;
          background-image: @grad_right;
          border-radius: 5px;
          &.van-hairline--surround {
            &::after {
              border-color: transparent;
            }
          }
        }
        &.isBlack {
          &.van-hairline--surround {
            &::after {
              border-radius: 10px;
            }
          }
        }
        &.disBtn {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>